import {Battle} from './battle';
import {Dex} from './dex';
// import {Pokemon} from './pokemon';
// import {Side} from './side';

// Создаем битву
const battle = new Battle({
	formatid: 'gen8anythinggoes'
});

battle.setPlayer('p1', {team: [{name: 'Mew', level: 100, evs:{hp: 31, atk: 31, def: 30, spa: 30, spd: 30, spe: 30}, ivs: {hp: 31, atk: 31, def: 30, spa: 30, spd: 30, spe: 30}, item: 'whiteherb', nature: 'adamant', gender: 'male', species: "Mew", ability: 'synchronize', moves: ['recover']}]});
battle.setPlayer('p2', {team: [{name: 'Ry', level: 100, evs:{hp: 31, atk: 31, def: 30, spa: 30, spd: 30, spe: 30}, ivs: {hp: 31, atk: 31, def: 30, spa: 30, spd: 30, spe: 30}, item: 'whiteherb', nature: 'adamant', gender: 'male', species: "Rhydon", ability: 'prankster', moves: ['sketch']}]});

battle.choose('p2', 'move 1');
battle.choose('p1', 'move 1');
console.log(battle.log);

//
// let side1 = new Side('test', battle, 1, []);
// let side2 = new Side('test', battle, 2, []);
// // Назначаем покемонов
// const player1Pokemon = new Pokemon('pikachu', side1);
// const player2Pokemon = new Pokemon('charmander', side2);
//
// battle.sides[0].active = [player1Pokemon];
// battle.sides[1].active = [player2Pokemon];
//
// // Выполняем ход
// const action = {
// 	choice: 'move',
// 	move: 'thunderbolt'
// };
//
// // Отправляем действие
// battle.chooseMove(action, battle.sides[0]);
//
// // Получаем результат
